import React from "react"
import styled from "styled-components"
import { GatsbyImage  } from "gatsby-plugin-image"
import IconApple from "components/icons/apple"
import IconSpotify from "components/icons/spotify"
import IconAmazon from "components/icons/amazon"
import AlbumGenres from "components/albums/genres"
import AlbumSchema from "components/albumSchema"

const Album = ({ album }) => {

  const platforms = ['Apple', 'Spotify'].filter(( platform ) => album[`link_${platform.toLocaleLowerCase()}`])

  const getIcon = ( slug ) => {
    switch (slug.toLowerCase()) {
      case 'apple': 
        return <IconApple />
      case 'spotify': 
        return <IconSpotify />
      case 'amazon': 
        return <IconAmazon />
      default:
        return <></>
    }
  }

  return (
    <StyledAlbum>
      <figure>
        {album.cover && (
          <GatsbyImage 
            image={album.cover.localFile.childImageSharp.gatsbyImageData} 
            alt={`${album.title} by ${album.artist.name} for the Noam Chomsky Music Project`} 
          />
        )}
      </figure>
      <header>
        <h1>{album.artist.name}</h1>
        <h2>{album.title}</h2>
      </header>
      {platforms.length > 0 && (
        <ul>
          {platforms.map((platform) => (
            <li key={`album-platform-${platform}`}>
              <a 
                href={album[`link_${platform.toLocaleLowerCase()}`]} 
                target="_blank" 
                rel="noopener noreferrer" 
                title={`Listen on ${platform}`}
              >
                {getIcon(platform)}
                <span className="screen-reader-text">{platform}</span>
              </a>
            </li>
          ))}
        </ul>
      )}
      <footer>
        <AlbumGenres genres={album.genre} slug={album.slug} />
        <span>{album.artist.location}</span>
      </footer>
      <AlbumSchema album={album} />
    </StyledAlbum>
  )
}

export default Album

const StyledAlbum = styled.article`
  text-align: center;
  width: 100%;
  figure {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 22em;
    background-color: var(--color-gray);
    div {
      box-shadow: rgba(0, 0, 0, 0.6) 0px 0.75em 2.5em -1em;
    }
  }
  @media ( max-width: 48em ) {
    figure {
      max-width: 17em;
    }
  }
  header {
    margin: 2em 0 0;
    
  }
  h1 {
    font-size: 1.5em;
    margin: 0;
    line-height: 1;
  }
  h2 {
    font-size: 1.125em;
    font-weight: 400;
    margin: 0.75em 0 0.5em;
    line-height: 1.2;
  }
  @media ( min-width: 48em ) {
    h1 {
      font-size: 1.75em;
    }
    h2 {
      font-size: 1.25em;
    }
  }
  > ul {
    list-style: none;
    margin: 1em 0 0.75em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    li:not(:last-of-type) {
      margin-right: 1.5em;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    ul {
      line-height: 1;
    }
    span {
      font-size: 0.75em;
      line-height: 1;
      display: inline-block;
      &::before {
        content: '\\2014';
        margin-left: 1em;
        margin-right: 1em;
      }
    }
  }
`