import React from "react"
import styled from "styled-components"

const StyledIconApple = styled.svg`
  height: 1.375em;
  width: 1.25em;
  transform: translate3d(0,-0.0625em,0);
  path {
    fill: currentColor;
  }
`

const IconApple = () => (
    <StyledIconApple xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.82 30.72"><path d="M25.82,22.54c-.84,2.52-3.7,8.13-7,8.18-1.93,0-3.06-1.23-5.24-1.23s-3.47,1.19-5.2,1.23C5,30.82,1.86,24.65,1,22.13a17.31,17.31,0,0,1-1-5.56c0-6.09,4.07-9.08,7.89-9.14,1.86,0,4.21,1.35,5.24,1.35s3.64-1.61,6.07-1.42A7.37,7.37,0,0,1,25,10.42a6.42,6.42,0,0,0-3.43,5.82A6.82,6.82,0,0,0,25.82,22.54ZM12.94,7.33A6.54,6.54,0,0,1,14.69,2.4,7.26,7.26,0,0,1,19.35,0,6.43,6.43,0,0,1,17.7,5,5.8,5.8,0,0,1,12.94,7.33Z"/></StyledIconApple>
)

export default IconApple
