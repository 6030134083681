import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Album from "components/album"
import Button from "components/button"
import IconArrowLeft from "components/icons/arrowLeft"
import IconArrowRight from "components/icons/arrowRight"
import { GatsbyImage  } from "gatsby-plugin-image"
import Backdrop from "components/backdrop"
import { useAlbums } from "components/albums/context"
import getAlbumLink from "utils/getAlbumLink"


const AlbumPageTemplate = ({ data }) => {

  const { albumsReleased } = useAlbums()
  const album = data.album

  const [prev, setPrev] = useState(false)
  const [next, setNext] = useState(false)

  useEffect(() => {
    albumsReleased.forEach(({ node }, i) => {
      if ( node.slug === album.slug ) {
        if ( albumsReleased[i+1] ) {
          setNext(albumsReleased[i+1].node)
        }
        else {
          setNext(albumsReleased[0].node)
        }
        if ( albumsReleased[i-1] ) {
          setPrev(albumsReleased[i-1].node)
        }
        else {
          setPrev(albumsReleased[albumsReleased.length - 1].node)
        }
      }
    })
  }, [albumsReleased, album.slug])

  return (
    <>
      <Helmet>
        <title>{album.title} by {album.artist.name} | {data.globals.site_title}</title>
      </Helmet>
      <StyledArtistBackdrop className="is-backdrop-faded">
        <Backdrop>
          {album.cover && (
            <GatsbyImage 
              image={album.cover.localFile.childImageSharp.gatsbyImageData} 
              alt={`${album.title} by ${album.artist.name} for the Noam Chomsky Music Project`} 
            />
          )}
        </Backdrop>
      </StyledArtistBackdrop>
      <StyledArtistPage>
        <StyledAboutLink as={Link} to="/">
          <IconArrowLeft /><span>About</span> 
        </StyledAboutLink>
        <div>
          <Link to="/"><IconArrowLeft /> About the Project</Link>
        </div>
        <StyledTitle as={Link} to="/">The<br /> <strong>Noam Chomsky</strong><br /> Music Project</StyledTitle>
        <Album album={album} />
        <nav>
          {prev && (
            <Button as={Link} to={getAlbumLink(prev)}><IconArrowLeft /></Button>
          )}
          {next && (
            <Button as={Link} to={getAlbumLink(next)}><IconArrowRight /></Button>
          )}
        </nav>
      </StyledArtistPage>
    </>
  )
}

export default AlbumPageTemplate


export const query = graphql`
  query($id: String!) {
    album: directusAlbum(id: { eq: $id }) {
      released
      artist {
        name
        location
        slug
      }
      genre {
        name
      }
      title
      slug
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(width:704)
          }
          publicURL
        }
      }
      link_amazon
      link_apple
      link_spotify
    }
    globals: directusGlobal {
      site_title
    }
  }
        
`
/*

  */


const StyledArtistBackdrop = styled.span`
  display: none;
  @media ( min-width: 60em ) {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
  }
`
const StyledArtistPage = styled.main`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(1.25 * var(--padding)) 0 calc(2* var(--padding) );
  background-color: var(--color-background);
  @media ( min-width: 60em ) {
    min-height: 100vh;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
  }
  @media ( min-width: 48em ) {
    padding: calc(2* var(--padding) ) 0;
  }
  > footer {
  }
  nav {
    margin-top: 1.5em;
    a:not(:last-of-type) {
      margin-right: 0.75em;
    }
  }
  > div:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    a {
      display: block;
      font-size: 0.75em;
      font-weight: 600;
      background-color: var(--color-white);
      padding: 0 0.5em;
      line-height: 2;
      height: 2em;
      svg {
        margin-top: 0.125em;
      }
      .can-hover &:hover,
      &:active {
        background-color: var(--color-gray);
        color: var(--color-white);
      }
    }
  }
  @media ( min-width: 60em ) {
    &::before {
      right: 0;
      margin-left: 0;
      left: auto;
      margin-right: -30%;
    }
  }
  @media ( min-width: 48em ) {
    > footer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
    nav {
      margin-top: 2.5em;
    }
  }
  @media ( min-width: 60em ) {
    padding: calc( 2 * var(--padding)) calc( 3 * var(--padding)) calc( 2 * var(--padding)) 0;
  }
`

const StyledAboutLink = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  @media ( max-width: 60em ) {
    left: -8vw;
  }
`

const StyledTitle = styled.a`
  font-size: 1.125em;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0.25em 0.5em;
  transition: background-color 0.1s ease, color 0.1s ease;
  .can-hover &:hover,
  &:active {
    background-color: var(--color-black);
    color: var(--color-white);
  }
  @media ( min-width: 48em ) {
    font-size: 0.9375em;
    margin-bottom: 2.5em;
    br {
      display: none;
    }
  }
`