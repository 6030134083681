import React from "react"
import styled from "styled-components"

const StyledIconAmazon = styled.svg`
  height: 1.3em;
  width: 1.5em;
  transform: translate3d(0, 0.0625em, 0);
  path {
    fill: currentColor;
  }
`

const IconAmazon = () => (
    <StyledIconAmazon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 332.28"><path d="M236.58,94c-9.68.77-20.86,1.51-32,3-17.12,2.21-34.24,5.22-48.39,11.91C128.63,120.09,110,143.93,110,179c0,44,28.3,66.36,64,66.36a121.84,121.84,0,0,0,30.53-3.71c14.15-4.49,26.07-12.67,40.21-27.6,8.19,11.21,10.41,16.43,24.57,28.34,3.73,1.5,7.45,1.5,10.41-.74,8.94-7.46,24.56-20.88,32.78-28.33,3.71-3,3-7.47.74-11.21-8.2-10.43-16.38-19.4-16.38-39.5V95.46c0-28.35,2.21-54.45-18.62-73.82C261.15,6,234.34,0,213.5,0h-8.95c-38,2.25-78.16,18.63-87.12,65.63-1.46,6,3,8.2,6,9l41.68,5.23c4.47-.78,6.69-4.5,7.43-8.24,3.74-16.39,17.14-24.58,32-26.08h3c8.91,0,18.59,3.74,23.82,11.16,5.93,9,5.21,20.91,5.21,31.34v6Zm0,43.28c0,17.16.72,30.57-8.2,45.46-5.21,10.44-14.14,17.16-23.82,19.4-1.49,0-3.7.77-5.95.77-16.37,0-26.08-12.67-26.08-31.34,0-23.85,14.16-35.05,32-40.27,9.68-2.24,20.86-3,32-3v9Zm137.13,177.2C391.91,299,399.6,271.09,400,256.5v-2.44a13.18,13.18,0,0,0-1.6-6.88c-3.65-4.44-30.37-8.52-53.4-1.62a63.71,63.71,0,0,0-17.4,8.5c-4.05,2.86-3.24,6.47.8,6.08,4.45-.82,10.13-1.2,16.6-2.05,14.16-1.18,30.74-1.59,34.78,3.67,5.64,6.87-6.47,36.87-11.75,49.84-1.59,4.06,2,5.69,5.68,2.84ZM2,256.5c52.17,47.41,120.92,75.78,197.79,75.78,47.72,0,102.73-13.38,145.2-38.9a150.49,150.49,0,0,0,16.56-10.95c7.29-5.25.82-13.38-6.46-10.15-3.23,1.22-6.87,2.86-10.1,4.07a389.91,389.91,0,0,1-140.76,26.76c-72,0-141.56-19.87-197.79-52.69C1.19,247.6-2.46,252.85,2,256.5Z"/></StyledIconAmazon>
)

export default IconAmazon
