import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import getAlbumLink from "utils/getAlbumLink"
import getAlbumGenresAsString from "utils/getAlbumGenresAsString"

const AlbumSchema = ({ album }) => {
  
  const data = useStaticQuery(
    graphql`
      query {
        globals: directusGlobal {
          site_title
        }
      }
    `
  )

  const jsonData = {
    "@context": "https://schema.org",
    "@type": "MusicAlbum",
    "byArtist": {
      "@type": "MusicGroup",
      "name": `${album.artist.name}`
    },
    "genre": `${getAlbumGenresAsString(album)}`,
    "image": `${album.cover.localFile.publicURL}`,
    "name": `${album.title} (${data.globals.site_title})`,
    /*"numTracks": "8",
    "track": [
      {
        "@type": "MusicRecording",
        "duration": "PT5M14S",
        "name": "Bloom",
        "url": "/artist/radiohead/album/the-king-of-limbs/track/bloom"
      },
      {
        "@type": "MusicRecording",
        "duration": "PT4M40S",
        "name": "Morning Mr Magpie",
        "url": "/artist/radiohead/album/the-king-of-limbs/track/morning-mr-magpie"
      }
    ],
    */
    "url": `${getAlbumLink(album)}`
  }

  return (
    <script 
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonData) }}
    />
  )
}
export default AlbumSchema