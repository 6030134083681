import React from "react"
import styled from "styled-components"

const StyledIconSpotify = styled.svg`
  height: 1.25em;
  width: 1.25em;
  path {
    fill: currentColor;
  }
`

const IconSpotify = () => (
    <StyledIconSpotify xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.73 29.73"><path d="M29.73,14.86A14.87,14.87,0,1,1,14.86,0,14.87,14.87,0,0,1,29.73,14.86Zm-4-2.89A1.41,1.41,0,0,0,25,10.62,25.25,25.25,0,0,0,12.68,7.76a24.68,24.68,0,0,0-7,.91,1.41,1.41,0,0,0-1,1.43,1.38,1.38,0,0,0,1.35,1.41H6a2.92,2.92,0,0,0,.77-.15C11.7,10,19.33,10.59,23.6,13.14a1.37,1.37,0,0,0,.77.23A1.39,1.39,0,0,0,25.76,12Zm-2.09,4.8A1.23,1.23,0,0,0,23,15.59a20.88,20.88,0,0,0-10.61-2.73,20,20,0,0,0-5.86.81,1.18,1.18,0,0,0-.93,1.24,1.16,1.16,0,0,0,1.16,1.17,2.52,2.52,0,0,0,.71-.16,19.4,19.4,0,0,1,14.3,1.76,1.43,1.43,0,0,0,.74.25,1.15,1.15,0,0,0,1.16-1.15Zm-1.86,4.17a1,1,0,0,0-.58-1C17,17.4,11.93,17.3,7,18.39a1,1,0,0,0,.1,1.95h0a3.47,3.47,0,0,0,.71-.16c4.3-.88,8.65-.78,12.39,1.47a1.15,1.15,0,0,0,.64.22.91.91,0,0,0,.91-.91v0Z"/></StyledIconSpotify>
)

export default IconSpotify